// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import SelectItem from '@/core/SelectItem.organism';
import { Icon } from '@/core/Icon.atom';
import { AddFormulaParameterModal } from '@/formula/AddFormulaParameterModal.molecule';
import { EditableText } from '@/core/EditableText.atom';

import { warnToast } from '@/utilities/toast.utilities';
import { isItemRedacted } from '@/utilities/redaction.utilities';

export interface FormulaEditorParam {
  name: string;
  item: { id: string; name: string; type?: string };
  identifier?: string;
}

interface FormulaParametersTableProps {
  resizeEnabled?: boolean;
  parameters: FormulaEditorParam[];
  updateParameterCallback: (updated: FormulaEditorParam, originalParam: FormulaEditorParam) => void;
  removeParameterCallback: (identifier: string) => void;
  insertParameter?: (param: string) => void;
  additionalItems?: any[];
  excludeStoreItems?: boolean;
  onItemSelect: (idx: any, item: any) => void;
  includeAddToDisplayPane?: boolean;
}

export const FormulaParametersTable: React.FunctionComponent<FormulaParametersTableProps> = ({
  resizeEnabled = true,
  additionalItems,
  insertParameter,
  parameters,
  onItemSelect,
  excludeStoreItems,
  updateParameterCallback,
  removeParameterCallback,
  includeAddToDisplayPane = true,
}) => {
  const { t } = useTranslation();
  const [showItemModal, setShowItemModal] = useState(undefined);

  const iconClass = resizeEnabled ? 'ml10' : 'ml5';

  const updateParameter = (updatedParameter: FormulaEditorParam, originalParameter: FormulaEditorParam) => {
    if (
      updatedParameter.name !== originalParameter.name &&
      _.some(parameters as any, { identifier: updatedParameter.identifier })
    ) {
      warnToast({ messageKey: 'FORMULA.VARIABLE_UNIQUE' });
    } else {
      updateParameterCallback(updatedParameter, originalParameter);
    }
  };

  return (
    <>
      <table className="table table-striped table-condensed mb3">
        <thead>
          <tr>
            <th className="min-width-47">{t('NAME')}</th>
            <th className="max-width-370">{t('ITEM')}</th>
            <th className="width-minimum" />
          </tr>
        </thead>
        <tbody>
          {_.isEmpty(parameters) && (
            <tr className="text-center text-italic">
              <td colSpan={3} className="flexCenter flexFill">
                {t('FORMULA.NO_VARIABLES')}
              </td>
            </tr>
          )}
          {_.map(parameters, (parameter, idx) => (
            <tr key={parameter.identifier} data-testid={`formulaParameter_${idx}`}>
              <td className="text-valign-middle flexColumnContainer">
                <strong>$</strong>
                <EditableText
                  testId="rowName"
                  value={parameter.identifier}
                  allowEditing={true}
                  inputClasses="flexFill text-bold width-50"
                  textClasses="textAlignLeft text-bold width-50"
                  maxDisplayChars={10}
                  onUpdate={(identifier) => updateParameter({ ...parameter, identifier, name: identifier }, parameter)}
                />
              </td>
              <td className={classNames(resizeEnabled ? 'min-width-200' : 'flexFill')}>
                {resizeEnabled ? (
                  <SelectItem
                    selectedItemId={parameter.item.id}
                    excludeStoreItems={excludeStoreItems}
                    showAddToDisplayPane={includeAddToDisplayPane}
                    additionalItems={additionalItems}
                    paramIndex={`${idx}`}
                    identifier={parameter.identifier}
                    extraClassNames={`select_${parameter.name}`}
                    onSelect={onItemSelect}
                    selectByIdAndGroup={true}
                  />
                ) : (
                  <span>
                    {isItemRedacted(parameter.item) && (
                      <Icon tooltip="NO_ITEM_ACCESS" icon="fa-exclamation-triangle" extraClassNames="sq-text-danger" />
                    )}
                    {parameter.item.name}
                  </span>
                )}
              </td>
              <td className="nowrap text-valign-middle">
                <Icon
                  icon="fa-plus"
                  testId={`insertParam_${parameter.identifier}`}
                  extraClassNames={iconClass}
                  onClick={() => insertParameter(`$${parameter.identifier}`)}
                  tooltip="ADD"
                />
                <Icon
                  icon="fa-pencil"
                  testId={`editParam_${parameter.identifier}`}
                  extraClassNames={iconClass}
                  onClick={() => setShowItemModal(parameter)}
                  tooltip="EDIT"
                />
                <Icon
                  icon="fa-remove"
                  testId={`removeParam_${parameter.identifier}`}
                  extraClassNames={iconClass}
                  onClick={() => removeParameterCallback(parameter.identifier)}
                  tooltip="REMOVE"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showItemModal && (
        <AddFormulaParameterModal
          parameters={parameters}
          parameter={showItemModal}
          onSave={updateParameter}
          onClose={() => setShowItemModal(undefined)}
          testId="functionParamModal"
        />
      )}
    </>
  );
};
export default FormulaParametersTable;
