import React from 'react';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import { FormBuilder } from '@/formbuilder/FormBuilder.page';
import { ButtonVariant } from '@/core/TextButton.atom';

interface SimpleSaveFormBuilderProps {
  testId?: string;
  // the formDefinition Array of FormElements defines the form to be rendered
  formDefinition: FormElement[];
  // the function that is called when the form is submitted
  submitFn: (values: {}) => void;
  // the function that is called when the cancel button is clicked
  closeFn: () => any;
  // if debug is enabled then the form values and form errors are rendered below this form;
  debug?: boolean;
  // option to have the submit button say something other than 'Save'
  submitBtnLabel?: string;
  // option to have different Save or Execute submit button
  submitBtnVariant?: ButtonVariant;
  // option to hide the submit button
  hideSubmit?: boolean;
  // option to hide the cancel button
  hideCancel?: boolean;
  // extra class-names for action buttons
  buttonExtraClassNames?: string;
  // this is useful for those rare cases where the submit button should remain in a loading state after the submitFn is
  // resolved (ex. oauth)
  continueProcessing?: boolean;
}

/**
 * SimpleSaveFormBuilder generates forms based on the provided form definition Array.
 * SimpleSaveFormBuilder is NOT TO BE USED for tools - use ToolFormBuilder for tools. SimpleSaveFormBuilder should
 * be used for forms that only display a save/cancel button, and do not need tool specific tracking.
 **/
export const SimpleSaveFormBuilder: React.FunctionComponent<SimpleSaveFormBuilderProps> = (props) => (
  <FormBuilder {...props} saveAndCancel={true} />
);
