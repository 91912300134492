// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { Checkbox } from '@/core/Checkbox.atom';
import { useTranslation } from 'react-i18next';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { Y_VALUE_BIN_MODES } from '@/tools/histogram/histogram.constants';
import { FormControl } from 'react-bootstrap';
import { useShowError } from '@/formbuilder/hooks/useShowError';
import { getFormFieldProps } from '@/formbuilder/formbuilder.utilities';

export type ValueBins = {
  binSize: number;
  numberOfBins: number;
  min: number;
  max: number;
};

export interface ValueBinsIF extends ValidatingFormComponent<ValueBins> {
  component: 'ValueBinsFormComponent';
  yValueBinMode: string;
  setBinCreationMode: (mode: string) => void;
  rowClasses: string;
  onBinSizeChange: (binSize: number) => void;
  onBinNumberChange: (binNumber: number) => void;
  onMaxChange: (max: number) => void;
  onMinChange: (min: number) => void;
}

const isNotValid = (value: number) => _.isNil(value) || _.isNaN(value);

export const ValueBinsFormComponent: React.FunctionComponent<ValueBinsIF> = (props) => {
  const {
    testId = 'valueBins',
    label,
    name,
    yValueBinMode,
    setBinCreationMode,
    rowClasses,
    value,
    onBinNumberChange,
    onBinSizeChange,
    onMaxChange,
    onMinChange,
  } = props;

  const { t } = useTranslation();
  const formState = useForm().getState();
  const { showError, setShowError } = useShowError(false);

  const isBinSizeInValid = (binSize: number) => !isNotValid(binSize) && binSize <= 0;

  const isNumberOfBinsInValid = (numberOfBins: number) => !isNotValid(numberOfBins) && numberOfBins <= 0;

  const isBinSizeAndNumberInValid = (value: ValueBins) => isNotValid(value?.binSize) && isNotValid(value?.numberOfBins);

  const defaultValidation = (value: ValueBins) =>
    isBinSizeAndNumberInValid(value) ||
    isBinSizeInValid(value?.binSize) ||
    isNumberOfBinsInValid(value?.numberOfBins) ||
    isNotValid(value?.min) ||
    isNotValid(value?.max) ||
    value?.min > value?.max;

  const getCustomErrorText = ({ binSize, numberOfBins, min, max }: ValueBins) => {
    if (isBinSizeInValid(binSize)) {
      return 'AGGREGATIONS.INVALID_BIN_SIZE';
    }
    if (isNumberOfBinsInValid(numberOfBins)) {
      return 'AGGREGATIONS.INVALID_BIN_NUMBER';
    }
    if (min > max) {
      return 'AGGREGATIONS.MIN_EXCEEDS_MAX';
    }
  };

  const includeZero = (value: number) => (_.isUndefined(value) ? '' : value);

  const getValue = (value: string) => (value === '' ? undefined : Number(value));

  return (
    <FormFieldWrapper
      testId={testId}
      label={label}
      showError={showError}
      customErrorText={getCustomErrorText(value)}
      wrapperClassNames="width-100percent">
      <Field name={name} validate={defaultValidation}>
        {({ input, meta }) => {
          const { showError, onChange, value } = getFormFieldProps(formState, input, meta, props);
          setShowError(showError);

          return (
            <>
              <table className="table table-condensed mb0">
                <tbody>
                  <tr className={rowClasses}>
                    <td>
                      <Checkbox
                        id="size"
                        label="AGGREGATIONS.SIZE_OF_BIN"
                        isChecked={yValueBinMode === Y_VALUE_BIN_MODES.SIZE}
                        type="radio"
                        onChange={() => setBinCreationMode(Y_VALUE_BIN_MODES.SIZE)}
                      />
                    </td>
                    <td>
                      <FormControl
                        autoComplete="off"
                        data-testid="binSize-input"
                        type="number"
                        size="sm"
                        className="width-55"
                        value={includeZero(value.binSize)}
                        onChange={(e) => {
                          const binSize = getValue(e.target.value);
                          onChange({ ...value, binSize });
                          onBinSizeChange(binSize);
                          setBinCreationMode(Y_VALUE_BIN_MODES.SIZE);
                        }}
                        isInvalid={showError && (isBinSizeInValid(value.binSize) || isBinSizeAndNumberInValid(value))}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Checkbox
                        id="number"
                        label="AGGREGATIONS.NUMBER_OF_BINS"
                        isChecked={yValueBinMode === Y_VALUE_BIN_MODES.NUMBER}
                        type="radio"
                        onChange={() => setBinCreationMode(Y_VALUE_BIN_MODES.NUMBER)}
                      />
                    </td>
                    <td>
                      <FormControl
                        autoComplete="off"
                        data-testid="numberOfBins-input"
                        type="number"
                        size="sm"
                        className="width-55"
                        value={includeZero(value.numberOfBins)}
                        onChange={(e) => {
                          const numberOfBins = getValue(e.target.value);
                          onChange({
                            ...value,
                            numberOfBins,
                          });
                          onBinNumberChange(numberOfBins);
                          setBinCreationMode(Y_VALUE_BIN_MODES.NUMBER);
                        }}
                        isInvalid={
                          showError && (isNumberOfBinsInValid(value.numberOfBins) || isBinSizeAndNumberInValid(value))
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="flexFill flexColumnContainer flexAlignCenter mt5" data-testid={`${testId}MinMax`}>
                <span className="pr10">{t('AGGREGATIONS.MIN')}</span>
                <FormControl
                  data-testid={`${testId}Min`}
                  autoComplete="off"
                  type="number"
                  size="sm"
                  className="width-55"
                  value={includeZero(value.min)}
                  onChange={(e) => {
                    const min = getValue(e.target.value);
                    onChange({ ...value, min });
                    onMinChange(min);
                  }}
                  isInvalid={showError && (isNotValid(value.min) || value.min > value.max)}
                />
                <span className="pl10 pr10">{t('AGGREGATIONS.MAX')}</span>
                <FormControl
                  data-testid={`${testId}Max`}
                  autoComplete="off"
                  type="number"
                  size="sm"
                  className="width-55"
                  value={includeZero(value.max)}
                  onChange={(e) => {
                    const max = getValue(e.target.value);
                    onChange({ ...value, max });
                    onMaxChange(max);
                  }}
                  isInvalid={showError && (isNotValid(value.max) || value.min > value.max)}
                />
              </div>
            </>
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
