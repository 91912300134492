// @ts-strict-ignore
import React from 'react';
import { FormControlFormComponent } from '@/formbuilder/FormControlFormComponent.atom';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { useShowError } from '@/formbuilder/hooks/useShowError';
import { getFormFieldWrapperProps } from '@/formbuilder/formbuilder.utilities';

export interface FormControlJsonIF extends ValidatingFormComponent<string | number> {
  component: 'JsonTextAreaFormComponent';
  placeholder?: string;
  onBlur?: (value: string) => void;
  type?: string;
  step?: number;
  size: string;
  rows?: number;
  value: string;
  disabled?: boolean;
}

export const JsonTextAreaFormComponent: React.FunctionComponent<FormControlJsonIF> = (props) => {
  const {
    name,
    extendValidation,
    validation,
    placeholder,
    onChange,
    onBlur,
    customErrorText,
    type = 'textarea',
    step,
    size,
    rows,
    value,
    disabled,
  } = props;

  const { showError, setShowError } = useShowError('');

  const jsonValidation = (value) => {
    try {
      JSON.parse(value.toString());
    } catch (e) {
      setShowError(e);
      return true;
    }
    return false;
  };

  const jsonCustomError = () => {
    if (customErrorText !== null && customErrorText !== undefined) {
      return customErrorText;
    }
    return showError.toString();
  };

  return (
    <FormControlFormComponent
      component="FormControlFormComponent"
      {...getFormFieldWrapperProps(props)}
      name={name}
      extendValidation={extendValidation}
      validation={(value) => {
        if (validation !== null && validation !== undefined) {
          return validation(value);
        }
        return jsonValidation(value);
      }}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      type={type}
      step={step}
      size={size}
      rows={rows}
      value={value}
      as="textarea"
      disabled={disabled}
      customErrorText={jsonCustomError()}
    />
  );
};
