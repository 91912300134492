import { useIsMounted } from '@/core/hooks/useIsMounted.hook';
import { useState } from 'react';

/**
 * A hook for managing the state that controls the display of form-field errors
 */
export const useShowError = <T>(defaultValue: T) => {
  const isMounted = useIsMounted();
  const [showError, setCanShowError] = useState(defaultValue);

  const setShowError = (showError: T) => {
    setTimeout(() => isMounted.current && setCanShowError(showError), 0);
  };

  return {
    setShowError,
    showError,
  };
};
